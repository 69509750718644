@import '~common/ui_imports';

.linkButton {
  @include card-box-shadow;
  @include ui_fade_transition(0, -40px, 0.8s);

  background-color: var(--ux-white);
  color: inherit;
  display: block;
  height: 350px;
  text-align: center;
  text-decoration: none;
  transition: top ease 0.2s;
  width: 350px;
  user-select: none;

  @include media-breakpoint-up(lg) {
    height: 360px;
    width: 360px;
  }

  img {
    height: auto;
    margin-top: 1.5rem;
    width: 75%;
  }

  &:hover {
    box-shadow: var(--ux-elevations-50);
    color: inherit;
    text-decoration: none;
    top: -0.625rem;
  }

  &:focus {
    box-shadow: 0 0 8px 0 #66afe9b2;
  }
}

.preText {
  @include font-type-20;
  color: var(--ux-gray-700);
  letter-spacing: 0.04rem;
  margin-top: 1.25rem;
  margin-bottom: 0;
}

.title {
  @include font-type-40--bold;
  color: var(--ux-gray-900);
  margin-top: 0.1rem;

  @include media-breakpoint-up(lg) {
    @include font-type-70--bold;
  }
}
