@mixin ui_fade_transition($initialHeight, $translateY, $duration) {
  position: relative;
  top: $initialHeight;
  transition: top ease $duration;

  animation: fadeIn ease $duration;
  -webkit-animation: fadeIn ease $duration;
  -moz-animation: fadeIn ease $duration;
  -o-animation: fadeIn ease $duration;
  -ms-animation: fadeIn ease $duration;

  @keyframes fadeIn {
    0% {
      @include fadeInStart($translateY);
    }
    100% {
      @include fadeInEnd;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      @include fadeInStart($translateY);
    }
    100% {
      @include fadeInEnd;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      @include fadeInStart($translateY);
    }
    100% {
      @include fadeInEnd;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      @include fadeInStart($translateY);
    }
    100% {
      @include fadeInEnd;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      @include fadeInStart($translateY);
    }
    100% {
      @include fadeInEnd;
    }
  }
}

@mixin fadeInStart($translateY) {
  opacity: 0;
  transform: translateY($translateY);
}

@mixin fadeInEnd {
  opacity: 1;
  transform: translateY(0);
}
