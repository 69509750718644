@import '~common/ui_imports';

.root {
  font-family: $body-fonts;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  padding: 2rem 0;

  @include media-breakpoint-up(md) {
    padding: 3rem 0;
  }
}

.centeredVertically {
  justify-content: center;
}

.header {
  margin-bottom: 2rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 3rem;
  }
}

.heading {
  margin-bottom: var(--synth-spacing-8);
}

.linkButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > *:first-of-type {
    margin: 0 0 var(--synth-spacing-6);
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;

    > *:first-of-type {
      margin: 0 var(--synth-spacing-6) 0 0;
    }
  }
}
